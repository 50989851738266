@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'norwester', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {margin: 0; height: 100%; overflow: hidden}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: norwester;
  src: url('https://cdn.pointandplace.com/services/dewars-diwali-app/assets/fonts/norwester.otf');
}

@font-face {
  font-family: inter;
  src: url('https://cdn.pointandplace.com/services/dewars-diwali-app/2024/fonts/inter.ttf');
}

.font-inter {
  font-family: 'inter';
}

.eky-diwali-file-input::file-selector-button{
  visibility: hidden;
}
.eky-diwali-file-input::before{
  content: '';
  display: inline-block;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  width: 60%;
}
.eky-diwali-file-input{
  width: 60%;
}

.eky-diwali-file-input:hover::before {
  border-color: black;
}

.eky-diwali-file-button::file-selector-button{
  visibility: hidden;
}
.eky-diwali-file-button::before{
  content: 'Upload a photo';
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 19px;
  text-align: center;
  border-radius: 10px;
  line-height: initial;
  padding: 15px 4px 15px 4px;
  color: #976046;
  background-color: white;
  border-radius: 10px;

  text-transform: uppercase;
  line-height: initial;

  width: 200px;
}

.eky-diwali-coming-soon-container{
  background-image: url("https://cdn.pointandplace.com/services/dewars-diwali-app/assets/dewars-diwali-coming-soon-background-001.png");
  background-repeat: no-repeat;
  background-size: cover; 
}
.eky-diwali-crop-photo,.eky-diwali-landing,.eky-diwali-personalise,.eky-check-your-message,.eky-diwali-add-photo,.eky-diwali-all-set,.eky-diwali-recieve-gift,.eky-diwali-terms-and-conditions{
  background-image: url("https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/background.png");
  background-repeat: no-repeat;
  background-size: cover; 
}
.eky-diwali-button-large{
  padding: 16px 4px 16px 4px;
  color: #A9725D;
  background-color: white;
  border-radius: 100px;

  text-transform: uppercase;
  line-height: initial;

  cursor: pointer;

  width: 240px;
  height: 60px;

  font-size: 21px;
}
.eky-diwali-button-large-blur {
  padding: 16px 4px 16px 4px;
  border-radius: 100px;

  text-transform: uppercase;
  line-height: initial;

  cursor: pointer;

  width: 240px;
  height: 60px;
  font-size: 21px;

  background-color: rgba(255, 255, 255, 0);
  border: 2px solid rgba(255, 255, 255, 1);
  color: white;
  text-align: center;
  text-decoration: none;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

}
.eky-diwali-button-small{
  padding: 15px 4px 15px 4px;
  color: #976046;
  background-color: white;
  border-radius: 100px;
  font-size: 14px;

  text-transform: uppercase;
  line-height: initial;

  width: 200px;

  cursor: pointer;
}

.eky-diwali-button-large.press,.eky-diwali-button-large-blur.press,.eky-diwali-button-small.press,.eky-diwali-button-popup-large.press {
  scale: 0.9;
}
.eky-diwali-button-large,.eky-diwali-button-small,.eky-diwali-button-large-blur,.eky-diwali-button-popup-large {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  user-select: none;
}

.eky-message-option-btn {
  color: #976046;
  background-color: white;
  border-radius: 10px;

  text-transform: uppercase;
  line-height: initial;

  width: 160px;
  height: 50px;

  cursor: pointer;

  background-color: rgba(255, 255, 255, 0.4);
  border: 2px solid #E8E8E8;
  text-align: center;
  text-decoration: none;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.eky-message-option-btn.selected {
  color: #976046;
  background-color: white;
  border-width: 2px;
  border-color: #E8E8E8;
}
.eky-diwali-button-popup-large.continue {
  padding: 16px 4px 16px 4px;
  color: white;
  background-color: #A9725D;
  border-radius: 100px;

  text-transform: uppercase;
  line-height: initial;

  cursor: pointer;

  width: 240px;
  height: 60px;

  font-size: 21px;
}
.eky-diwali-button-popup-large.back {
  padding: 16px 4px 16px 4px;
  color: #A9725D;
  background-color: white;
  border-radius: 100px;

  text-transform: uppercase;
  line-height: initial;

  cursor: pointer;

  width: 240px;
  height: 60px;

  font-size: 21px;

  border-width: 2px;
  border-color: #A9725D;
}
.eky-diwali-button-terms-and-conditions{
  padding: 13px 4px 13px 4px;
  color: #976046;
  background-color: white;
  border-radius: 10px;

  text-transform: uppercase;
  line-height: initial;

  cursor: pointer;
}
.eky-button-text-main{
  font-size: 19px;
  text-align: center;
  user-select: none;
}
.eky-button-text-sub{
  font-size: 12px;
  text-align: center;
}
.eky-button-text-terms-and-conditions{
  font-size: 24px;
  text-align: center;
}

.eky-diwali-input-box.from {
  padding-top: 9px;
}
.eky-diwali-input-box.to {
  padding-top: 9px;
}
.eky-diwali-input-box{
  border-radius: 10px;
  background-color: white;
  padding: 12px;
}

.divider{
  border-style: solid;
  border-top-width: 1px;
  width: 100%;
  border-color: #B5978C;
}

.eky-check-your-message-box{
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  opacity: 60%;
  padding: 4rem 4rem 4rem 4rem;

  width: 100%;

  text-align: center;
  justify-content: center;

  color: black;

  min-height: 300px;

  justify-content: space-between;

  font-size: 24px;
  text-transform: uppercase;
  
}

.eky-diwali-image-upload-container{
  width: 80vw;
  height: 80vw;
  background-color: white;

  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
}


.eky-diwali-image-upload-container-crop{
  width: 80vw;
  height: 80vw;
  overflow: hidden;
  border-radius: 100%;

  z-index: 2;
}
input {
  color: black;
}
input::placeholder {
  color: #B5978C;
}
input,
input::placeholder{
  font-size: 20px;
  width: 100%;
  
  background-color: white;
}
textarea {
  color: black;
}
textarea::placeholder {
  color: #B5978C;
}
textarea,
textarea::placeholder{
  font-size: 20px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 80px;
}
.eky-diwali-input-box.invalid > input::placeholder {
  color: red;
}
.eky-diwali-input-box.invalid > div >  textarea::placeholder {
  color: red;
}

.eky-diwali-unique-code-input::placeholder{
  font-size: 15px;
  width: 100%;
  color: black;
}

textarea{
  line-height: initial;
}
input[type="tel"] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}
.eky-diwali-valid-unique-code, .eky-diwali-valid-unique-code > div > input{
  color: black;
}
.eky-diwali-invalid-unique-code, .eky-diwali-invalid-unique-code > div > input{
  color: red;
}

.eky-diwali-spinner{
  background-color: rgba(0,0,0,.5);
}

.eky-diwali-disabled-button{
  opacity: 60%;
}

/* Custom CropperJS CSS  */
.cropper-container.cropper-bg,.cropper-wrap-box,.cropper-drag-box,.cropper-crop-box,.cropper-view-box,.cropper-face{
  border-radius: 100%;
}
.cropper-bg{
  background-image: none !important;
}
.cropper-line,.cropper-face,.cropper-dashed,.cropper-center,.cropper-point{
  opacity: 0 !important;
}
.cropper-view-box{
  outline: none !important;
}
/* .cropper-modal,.cropper-drag-box{
  background-color: transparent !important;
} */

.code-display {
  display: flex;
  justify-content: space-around;
  color: white;
  font-size: 79px;
  align-items: center;
}

.mandala-group-spin-right {
  animation: spin 70s linear infinite;
}

.mandala-group-spin-left {
  animation: spin-left 70s linear infinite;
}

.mandala-group-spin-right-faster {
  animation: spin-faster 45s linear infinite;
}

.eky-diwali-crop-arrow-right {
  animation: crop-arrow-right 1.8s linear infinite;
}

.eky-diwali-crop-arrow-left {
  animation: crop-arrow-left 1.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-faster {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes crop-arrow-right {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(-14px, 14px);
  }
  25% {
    transform: translate(-14px, 14px);
  }
  50% {
    transform: translate(-14px, 14px);
  }
  70% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes crop-arrow-left {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(14px, -14px);
  }
  25% {
    transform: translate(14px, -14px);
  }
  50% {
    transform: translate(14px, -14px);
  }
  70% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}